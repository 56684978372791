import React from 'react';
import Link from 'components/Link';
import logo from 'utils/images/hiveonasic-asic-white.svg';
import tg from 'utils/images/telegram.svg'
import styles from './community.module.css';

const Community = () => (
  <div className={styles.wrapper}>
    <div className={styles.links}>
      <a href="https://hiveos.farm/asic" className={[styles.link, styles.logo].join(' ')}>
        <img src={logo} alt="hiveon asic logo in footer"  width="236" className={styles.img} />
      </a>
      <Link external href="https://t.me/hiveonasic" className={styles.link}>
        <img alt="telegram icon" src={tg} />
        <span className={styles.communityTgTitle}>РУ</span>
        <span className={styles.communityTgDesc}>Чат и помощь</span>
      </Link>
      <Link external href="https://t.me/hiveonasic_en" className={styles.link}>
        <img alt="telegram icon" src={tg} />
        <span className={styles.communityTgTitle}>EN</span>
        <span className={styles.communityTgDesc}>Chat and Support</span>
      </Link>
    </div>
  </div>
)


export default Community;
