import React, { Component, Fragment } from 'react';
import cx from 'classnames';
import Link from 'components/Link';
import styles from 'pages/pages.module.css';
import s from './GetStarted.module.css';
import boost from 'utils/images/boost.svg';
import WL from 'constants/whiteLabel';
import { HOW_TO_ADD_ASIC_LINK, LEARN_ASIC_LINK } from 'constants/index';

class GetStarted extends Component {
  state = {
    isCollapsed: false
  }

  componentDidMount() {
    if (typeof(window) !== 'undefined') {
      const isCollapsed = window.localStorage.getItem('asicCommunityIsCollapsed') === 'true';

      this.setState({ isCollapsed });
    }
  }

  handleClose = () => {
    this.setState({ isCollapsed: true });

    if (typeof(window) !== 'undefined') {
      window.localStorage.setItem('asicCommunityIsCollapsed', 'true');
    }
  }

  handleOpen = () => {
    this.setState({ isCollapsed: false });

    if (window) {
      window.localStorage.setItem('asicCommunityIsCollapsed', 'false');
    }
  }

  render () {
    const { isCollapsed } = this.state;

    if (!WL.showGetStarted) {
      return null;
    }

    return (
      <Fragment>
        <section className={cx(styles.content, s.getStarted, { [s.isShown]: !isCollapsed })}>
          <div className={s.wrapper}>
            <img src={boost} alt="boost" className={s.img} />
            <div className={s.call}>
              <h3 className={styles.accent}>Ready for a boost?</h3>
              <p>
                Find out how to add an ASIC to Hive OS <Link external className={s.link} href={HOW_TO_ADD_ASIC_LINK}>HERE</Link>
              </p>
              <p>
                Learn how to update an existing ASIC <Link external className={s.link} href={LEARN_ASIC_LINK}>HERE</Link>
              </p>
            </div>
            <div className={s.btnHolder}>
              <Link external noRef href={WL.getRegisterLink()} className="btn-primary-big">
                Sign up for free
              </Link>
            </div>
          </div>
          <div className={s.close} onClick={this.handleClose} role="presentation" />
        </section>
        <section className={cx(s.getStartedCollapsed, { [s.isShown]: isCollapsed })}>
          <img
            src={boost}
            alt="boost"
            className={s.imgCollapsed}
            onClick={this.handleOpen}
            role="presentation" 
          />
        </section>
      </Fragment>
    )
  }
}

export default GetStarted
